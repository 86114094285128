
import { defineComponent } from 'vue';
import { CardMedSession, Snackbar, Popup } from '@/components/';
import { MedSessionsController, UserController, ContentController } from '@/controller';
import store from '@/store/index';
import { Button } from '@/ui';
import { DeviceService } from '@/utils';

export default defineComponent({
    name: "Home",
    components: { CardMedSession, Snackbar, Button, Popup },
    data(){
        return{
            store,
            user: {} as any,
            medSessions: [] as Array<any>,
            icons: {
                logo: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 997.7 939.3" enable-background="new 0 0 997.7 939.3" xml:space="preserve"><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M572.9,902.7L283.5,194.4C201.6,361.1,119.7,527.8,37.7,694.5"/><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M960,744.9L670.6,36.6c-82,166.7-163.9,333.4-245.9,500.1"/></svg>',
                herz_outline: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 300 300" enable-background="new 0 0 300 300" xml:space="preserve"><g id="heart-svgrepo-com" transform="translate(0 -28.501)"><path fill="none" stroke="#183E64" stroke-width="45" stroke-miterlimit="10" d="M252.2,91.1c-26.4-26.3-69.2-26.3-95.5,0l-6.7,6.7l-6.7-6.7C117,64.7,74.3,64.6,48,90.9c0,0,0,0-0.1,0.1c-26.3,26.3-26.3,69.1,0,95.4c0,0,0,0,0.1,0.1l97.2,97.2c2.7,2.7,7,2.7,9.8,0.1l0,0l97.4-97C278.4,160.3,278.4,117.6,252.2,91.1L252.2,91.1z"/></g></svg>',
                apple_share: '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve"><path fill="#0076FF" d="M30.3,13.7L25,8.4l-5.3,5.3l-1.4-1.4L25,5.6l6.7,6.7L30.3,13.7z"/><path fill="#0076FF" d="M24,7h2v21h-2V7z"/><path fill="#0076FF" d="M35,40H15c-1.7,0-3-1.3-3-3V19c0-1.7,1.3-3,3-3h7v2h-7c-0.6,0-1,0.4-1,1v18c0,0.6,0.4,1,1,1h20c0.6,0,1-0.4,1-1V19c0-0.6-0.4-1-1-1h-7v-2h7c1.7,0,3,1.3,3,3v18C38,38.7,36.7,40,35,40z"/></svg>',
                chrome_three_dots: '<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="800px" height="800px" viewBox="0 0 800 800" enable-background="new 0 0 800 800" xml:space="preserve"><path d="M475,650c0,41.4-33.6,75-75,75s-75-33.6-75-75s33.6-75,75-75S475,608.6,475,650z M475,400c0,41.4-33.6,75-75,75s-75-33.6-75-75s33.6-75,75-75S475,358.6,475,400z M475,150c0,41.4-33.6,75-75,75s-75-33.6-75-75s33.6-75,75-75S475,108.6,475,150z"/></svg>'
            },
            responseState: '',
            snackbar: {
                show: false,
                text: '',
                error: false
            },
            showConfirmDeleteAccount: false,
            dontShowAgain: false,
            paymentBrands: [],
            lastShown: 0 as any,
            browser: '',
            appleMobile: false as Boolean,
            hasServiceWorker: false as Boolean,
            isInWebAppiOS: false as Boolean,
            isInWebAppChrome: false as Boolean,
            showA2hsApple: false as Boolean,
            showA2hsAndroid: false as Boolean,
            showPopup: false,
            popupUri: ''
        }
    },
    created(){
        //this.windowNavigator = window.navigator;
        (document.getElementById('app') as HTMLElement).scrollIntoView({ behavior: 'smooth' });

        this.fetchData();
        //https://www.mediaevent.de/javascript/progressive-web-app.html
        this.prepareAddToHomeScreen();
        
    },
    updated(){
        this.medSessions = MedSessionsController.medSessions
    },
    computed:{
        getNewReleasedMedSessions(){
            if(this.medSessions.length>0){
                let sessions :any[] = this.medSessions                
                sessions = sessions.sort((a : any, b : any) => a.released - b.released);
  
                const result = []
                result.push(sessions[sessions.length-1])
                if(sessions.length > 1){
                    result.push(sessions[sessions.length-2])
                }
                return result;
            } else {
                return false
            }
        },
        getRecentlyPlayedMedSessions(){
            let recent :any[] = [];
            this.medSessions.forEach((session:any) =>{
                if(session.statistic.startedAt){
                    recent.push(session)
                }
            })
            if(recent.length >0){
                recent = recent.sort((a : any, b : any) => b.statistic.startedAt - a.statistic.startedAt);
                const lastThree :any[] = recent.filter((session:any, index:any) => index < 3);
                return lastThree
            }
            return recent;
        }
    },
    methods: {
        /*getRecentlyPlayedMedSessions(){
            let recent :any[] = []           
            MedSessionsController.medSessions.forEach((session:any) =>{
                if(session.statistic.startedAt){
                    recent.push(session)
                }
            })
            recent = recent.sort((a : any, b : any) => b.statistic.startedAt - a.statistic.startedAt);
            const lastThree = recent.filter((session:any, index:any) => index < 3);
            console.log('lastThree', lastThree)
            return lastThree
        },*/
        handleOpenDetailView(sessionId : any){
            //this.handleGetMedSession(sessionId);
            this.$router.push('/medsession/'+ sessionId)
        },       
        handleManageAbo(){
            this.$router.push('/manageabo')
        },
        handleGoToFAQ(){
            this.$router.push('/faqs')
        },
        handleGoToContent(){
            this.$router.push('/about')
        },
        handleOpenAboutPopup() {
            this.popupUri = ContentController.ABOUT_URI as string
            this.showPopup = true
        },
        async fetchData(){
            this.user = UserController.user
            this.medSessions = MedSessionsController.medSessions;

            this.checkDirtyMedSessions();           
        },  
        checkDirtyMedSessions(){
            if(MedSessionsController.isDirty){
                setTimeout(() => {
                    this.checkDirtyMedSessions()
                }, 500)
            }
            this.medSessions = MedSessionsController.medSessions;

        },
        handleShowSnackbar(text:string, error : boolean){
            this.snackbar.show = true
            this.snackbar.text = text
            this.snackbar.error = error

            setTimeout(() => {
                this.snackbar.show = false
           }, 5000)
        }, 
        /*getDisabledCard(statistic:any){           
            if(this.user.freeSessionsLeft >0){
                return false
            } else if (this.user.freeSessionsLeft <= 0 && statistic && statistic.available==1){
                return false
            } else {
                return true
            }
        },*/
        /*async getMedSessions(){
            const res : any = await MedSessionsController.getMedSessions();
            if(res.data.success){
                this.medSessions = MedSessionsController.medSessions;
                this.recentlyPlayed = this.getRecentlyPlayedMedSessions();
            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
        },
        async handleGetMedSession(sessionId : any){
            let res : any = await MedSessionsController.getMedSession(sessionId)
            if(res.data.success){
                this.$router.push('/medsession/'+ res.data.data.uid)
            } else {
                if(res.data.errors[0].field == 'abo' && !this.dontShowAgain){
                    this.handleShowNoFreeSessions = true
                } else{
                    this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
                }
            }
        },*/
        hideA2hs() {
            this.showA2hsApple = false
            this.showA2hsAndroid = false
            console.log('hideA2hs', this.showA2hsAndroid, this.showA2hsApple)
        },
        prepareAddToHomeScreen() {
            this.browser = DeviceService.browser
            this.hasServiceWorker = DeviceService.serviceWorker
            this.appleMobile = DeviceService.appleMobile
            this.isInWebAppiOS = DeviceService.isInWebAppiOS
            //console.log('this.isInWebAppiOS, appleMobile', this.isInWebAppiOS, this.appleMobile);
            //const ng : any = window.navigator;
            //this.isInWebAppiOS = (ng.standalone == true);
            this.isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches);
            //this.platform = ng.platform
            //this.isSafari = ng.userAgent.match(/safari/i) // /^((?!chrome|android).)*safari/i.test(ng.userAgent)
            //this.isIPad = ng.userAgent.match(/iPad/i) != null
            //this.hasServiceWorker = ("serviceWorker" in ng)
            
            if (DeviceService.serviceWorker) {
                //if ((ng.platform === 'iPhone' || ng.platform === 'iPad') && (/^((?!chrome|android).)*safari/i.test(ng.userAgent))) {
                if (DeviceService.appleMobile && DeviceService.browser == 'Safari') {
                // Die App ist noch nicht installiert
                if (!DeviceService.isInWebAppiOS) {
                    this.showA2hsApple = true
                        /** [WS 2023-10-02] insists on that */
                    /* this.lastShown = localStorage.getItem('ALPrelax_addToHomeScreenLastShown')
                    let now : any = new Date().getTime();
                    if (isNaN(this.lastShown) || !this.lastShown || this.lastShown == '' || parseInt(this.lastShown) == 0 || (parseInt(this.lastShown) + 1000 * 60 * 60 * 24 * 7) <= now) { */
                        //localStorage.setItem ("ALPrelax_addToHomeScreenLastShown", now);
                        //this.$router.push('/a2hs');
                    //}
                } else if (!this.isInWebAppChrome) {
                    this.showA2hsAndroid = true
                }
            }
        }
    }
}
});
